<template>
	<div>
		<div class="CusInfo CusCommon">
			<div class="title">客户概况：</div>
			<div class="CusTypes displayCommon">
				<div class="Item" v-for="(item,index) in CusTypesList" :key="index" :class="{borderColor:true,ActiveClass:index == currentIndex}"
				 @click="SingleIndex(item,index)">
					<div class="displayCommon"><span style="margin-right:8px;">{{item.TypeName}}</span>
						<el-popover v-if="item.Id==4 ||item.Id==5 || item.Id==6" placement="top-start" width="200" trigger="hover"
						 :content="item.Prompt">
							<span slot="reference"> <i class="el-icon-warning-outline"></i></span>
						</el-popover>
					</div>
					<div class="NumStyle">{{item.MemberCount}}</div>
				</div>
			</div>
		</div>
		
		
		<div class="CusCommon">
			<el-form :model="ruleForm" ref="ruleForm" label-width="140px">
				<div class="filter-container">
					<div class="filter-item">
						<el-form-item label="关键字:" prop="searchKey">
							<el-input v-model="ruleForm.searchKey" @keyup.enter.native="handlefilter" style="width: 228px;" placeholder="手机号/微信昵称/姓名/会员编码"></el-input>
						</el-form-item>
					</div>
				</div>
				<div class="wholeFilter" v-show='IsfilterShow'>
					<div class="filter-container">
						<div class="filter-item">
							<el-form-item label="上次支付时间：" prop="lastTime">
								<el-date-picker v-model="ruleForm.lastTime" type="datetimerange" range-separator="~" start-placeholder="开始时间"
								 end-placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
								</el-date-picker>
							</el-form-item>
						</div>
						<div class="filter-item">
							<el-form-item label="成为客户时间：" prop="CustomTime">
								<el-date-picker v-model="ruleForm.CustomTime" type="datetimerange" range-separator="~" start-placeholder="开始时间"
								 end-placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
								</el-date-picker>
							</el-form-item>
						</div>
					</div>
					<div class="filter-container">
						<div class="filter-item">
							<el-form-item label="成为会员时间：" prop="UsersTime">
								<el-date-picker v-model="ruleForm.UsersTime" type="datetimerange" range-separator="~" start-placeholder="开始时间"
								 end-placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
								</el-date-picker>
							</el-form-item>
						</div>
						<div class="filter-item">
							<el-form-item label="成为推手时间：" prop="DistriTime">
								<el-date-picker v-model="ruleForm.DistriTime" type="datetimerange" range-separator="~" start-placeholder="开始时间"
								 end-placeholder="结束时间" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss">
								</el-date-picker>
							</el-form-item>
						</div>
					</div>

					<div class="filter-container">
						<div class="filter-item">
							<el-form-item label="客户身份：" prop="identityIds">
								<el-select v-model="ruleForm.identityIds" placeholder="请选择" filterable multiple>
									<el-option v-for="item in identityList" :key="item.Value" :label="item.Text" :value="item.Value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="filter-item">
							<el-form-item label="客户标签：" prop="labelIds">
								<el-select v-model="ruleForm.labelIds" placeholder="请选择" filterable multiple style="width:300px">
									<el-option v-for="item in labelList" :key="item.Value" :label="item.Text" :value="item.Value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<!-- <div class="filter-item">
						<el-form-item label="来源方式：" prop="StyleIds">
							<el-select v-model="ruleForm.StyleIds" placeholder="请选择" filterable multiple>
								<el-option v-for="item in StyleList" :key="item.Id" :label="item.Name" :value="item.Id">
								</el-option>
							</el-select>
						</el-form-item>
					</div> -->
					</div>
					<div class="filter-container">
						<div class="filter-item">
							<el-form-item label="性别：" prop="SexId">
								<el-select v-model="ruleForm.SexId" placeholder="请选择" filterable clearable>
									<el-option :value="null" label="全部"></el-option>
									<el-option v-for="item in SexList" :key="item.Id" :label="item.Name" :value="item.Id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>

						<div class="filter-item">
							<el-form-item label="微信授权：" prop="authorize">
								<el-select v-model="ruleForm.authorize" placeholder="请选择" filterable clearable>
									<el-option :value="null" label="全部"></el-option>
									<el-option v-for="item in authorizeList" :key="item.Id" :label="item.value" :value="item.Id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>

						<div class="filter-item">
							<el-form-item label="微信地域：" prop="wePlaceIds">
								<el-select v-model="ruleForm.wePlaceIds" placeholder="请选择" filterable multiple>
									<el-option v-for="item in wePlacelist" :key="item.id" :label="item.name" :value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="filter-item">
							<el-form-item label="归属门店： " prop="storeId">
								<el-select v-model="ruleForm.storeId" placeholder="请选择" clearable filterable>
									<el-option :value="null" label="全部"></el-option>
									<el-option v-for="item in storeList" :key="item.Value" :label="item.Text" :value="item.Value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
						<div class="filter-item">
							<el-form-item label="锁粉店员：" prop="lockId">
								<el-select v-model="ruleForm.lockId" placeholder="请选择" clearable filterable>
									<el-option :value="null" label="全部"></el-option>
									<el-option v-for="item in lockList" :key="item.Value" :label="item.Text" :value="item.Value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
					<div class="filter-container">

						<div class="filter-item">
							<!-- onkeypress="return( /(^[1-9]{1}[0-9]*$)/.test(String.fromCharCode(event.keyCode) ) );" -->
							<el-form-item label="积分区间:" prop="minScore" style="margin-left: 0px!important;display: inline-block;">
								<el-input v-model="ruleForm.minScore" placeholder="最小积分" style="width:90px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[1-9]\d*/)||'';}).call(this);"></el-input>
							</el-form-item>
							<span style="margin: 0 5px;">~</span>
							<el-form-item prop="maxScore" style="margin-left: 0px!important;display: inline-block;" label-width="0px">
								<el-input v-model="ruleForm.maxScore" placeholder="最大积分" style="width:90px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[1-9]\d*/)||'';}).call(this);"></el-input>
							</el-form-item>
						</div>
						<div class="filter-item">
							<el-form-item label="支付次数:" prop="minPay" style="margin-left: 0px!important;display: inline-block;">
								<el-input v-model="ruleForm.minPay" placeholder="最小次数" style="width:90px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
							</el-form-item>
							<span style="margin: 0 5px;">~</span>
							<el-form-item prop="maxpay" style="margin-left: 0px!important;display: inline-block;" label-width="0px">
								<el-input v-model="ruleForm.maxpay" placeholder="最大次数" style="width:90px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[0-9]\d*/)||'';}).call(this);"></el-input>
							</el-form-item>
						</div>
					</div>
					<div class="filter-container">
						<div class="filter-item">
							<el-form-item label="笔单价:" prop="minPrice" style="margin-left: 0px!important;display: inline-block;">
								<el-input v-model="ruleForm.minPrice" placeholder="最小金额" style="width:90px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
							</el-form-item>
							<span style="margin: 0 5px;">~</span>
							<el-form-item prop="maxPrice" style="margin-left: 0px!important;display: inline-block;" label-width="0px">
								<el-input v-model="ruleForm.maxPrice" placeholder="最大金额" style="width:90px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
							</el-form-item>
						</div>
						<div class="filter-item">
							<el-form-item label="累计支付金额:" prop="mintotalPrice" style="margin-left: 0px!important;display: inline-block;">
								<el-input v-model="ruleForm.mintotalPrice" placeholder="最小金额" style="width:90px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
							</el-form-item>
							<span style="margin: 0 5px;">~</span>
							<el-form-item prop="maxtotalPrice" style="margin-left: 0px!important;display: inline-block;" label-width="0px">
								<el-input v-model="ruleForm.maxtotalPrice" placeholder="最大金额" style="width:90px;" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
							</el-form-item>
						</div>
					</div>
				</div>
				<div class="filter-container" style="padding-left:100px;">
					<el-button type="primary" @click="handlefilter" style="margin-right:12px;">查询</el-button>
					<buttonPermissions :datas="'addCustomer'">
						<el-button type="primary" @click="AddMember">添加会员</el-button>
					</buttonPermissions>
					<el-button type="primary" @click="resetForm('ruleForm')" style="margin-left:12px;">重置</el-button>
					<span style="font-size: 14px;margin-left:30px;cursor: pointer;" @click="handlefilteClick">
						<span style="color: #409EFF;">{{btnText}}</span>
						<i :class="IsfilterShow?'el-icon-arrow-up':'el-icon-arrow-down'" style="color: #409EFF;"></i>
					</span>
				</div>
			</el-form>
		</div>
		<!-- //表格 -->
		<!-- 表格 -->
		<!-- -->
		<div class="table-container" style="width: 100%;margin-bottom: 15px;">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading" ref="multipleTable" @sort-change="sortChange"
			 @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="客户" width="180">
					<template slot-scope="scope">
						<div class="productInfo">
							<div class="Imgs">
								<img v-if="scope.row.WxHeadUrl" :src="scope.row.WxHeadUrl">
								<img v-else :src="defaultHeader">
							</div>
							<div class="Contains" style="margin-left: 8px;">
								<div class="top" style="display:flex">
									<div class="overFlow">
										{{scope.row.NameShow}}
									</div>
									<!-- <div class="Activestyle" v-if="scope.row.VipLevel">VIP{{scope.row.VipLevel}}</div> -->
								</div>
								<div style="display: inline-block;margin-top: 8px;">{{scope.row.Phone}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="会员级别" prop="VipNo" width="110">
					<template slot-scope="scope">
						<div v-if="scope.row.VipLevel">
							<div>VIP{{scope.row.VipLevel}}</div>
							<div>{{scope.row.VipNo}}</div>
						</div>
						<div v-else>--</div>
					</template>
				</el-table-column>
				<el-table-column label="分销级别">
					<template slot-scope="scope">
						<div style="color:#409EFF;">{{scope.row.DistributRoleName}}</div>
					</template>
				</el-table-column>
				<el-table-column sortable label="积分" prop="Point">
					<template slot-scope="scope">
						<div>{{scope.row.Point}}</div>
					</template>

				</el-table-column>
				<el-table-column sortable label="支付次数" prop="PayCount">
					<template slot-scope="scope">
						<div>{{scope.row.PayCount}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="PayMoney" sortable label="累计支付"></el-table-column>
				<el-table-column prop="LastPayTime" sortable label="上次支付时间"></el-table-column>
				<el-table-column prop="AverageOrderPriceShow" sortable label="笔单价">
					<template slot-scope="scope">
						<div>{{scope.row.AverageOrderPriceShow}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" sortable label="成为客户时间"></el-table-column>
				<el-table-column label="操作" width="240">
					<template slot-scope="scope">
						<el-button type="text" @click="Checkdetail(scope.row)">查看</el-button>
						<buttonPermissions :datas="'addLabel'">
							<el-button type="text" style="margin-left: 15px;" @click="setLabel(0,scope.row)">设置标签</el-button>
						</buttonPermissions>
						<el-dropdown trigger="click" style="margin-left: 10px;" v-if="isMoreShow1||scope.row.MemberDistributorRoleId==0">
							<span class="el-dropdown-link" style="color:#409EFF;cursor: pointer;" v-if="isMoreShow1||scope.row.MemberDistributorRoleId==0">
								更多
							</span>
							<el-dropdown-menu slot="dropdown" v-if="isMoreShow1||scope.row.MemberDistributorRoleId==0">
								<el-dropdown-item>
									<buttonPermissions :datas="'changeLevel'">
										<div @click="Edituserlevel(0,scope.row)">修改客户等级</div>
									</buttonPermissions>
								</el-dropdown-item>

								<el-dropdown-item>
									<buttonPermissions :datas="'givePoint'">
										<div @click="AddScore(0,scope.row)">赠送积分</div>
									</buttonPermissions>
								</el-dropdown-item>

								<el-dropdown-item>
									<buttonPermissions :datas="'deductPoints'">
										<div @click="ReduceScore(0,scope.row)">扣减积分</div>
									</buttonPermissions>
								</el-dropdown-item>

								<el-dropdown-item>
									<buttonPermissions :datas="'editStoredoor'">
										<div @click="EditSelect(0,scope.row,2)">修改归属门店</div>
									</buttonPermissions>
								</el-dropdown-item>

								<el-dropdown-item>
									<buttonPermissions :datas="'editLockStaff'">
										<div @click="EditSelect(1,scope.row,2)">修改锁粉店员</div>
									</buttonPermissions>
								</el-dropdown-item>

								<el-dropdown-item>
									<div @click="Authbutor(scope.row)" v-if="scope.row.MemberDistributorRoleId==0">授权推手</div>
								</el-dropdown-item>

								<el-dropdown-item>
									<buttonPermissions :datas="'Editlockhand'" v-if='scope.row.MemberDistributorRoleId==0'>
										<div @click="editLockpush(scope.row)">修改锁粉推手</div>
									</buttonPermissions>
									
								</el-dropdown-item>

							</el-dropdown-menu>
						</el-dropdown>

						<!-- <span style="color:#f00;margin-left:10px" @click="deleteChose">删除</span> -->
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;background:#fff;" v-if="Total">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;" v-if="Total">
					<el-checkbox @change="tabCheckedChange" v-model="checkedAll">当前页全选</el-checkbox>
					<buttonPermissions :datas="'batchAddLabel'">
						<el-button size="mini" style="margin-left: 20px;" @click="setLabel(1,'')">设置标签</el-button>
					</buttonPermissions>
					<buttonPermissions :datas="'batchChangeLevel'">
						<el-button size="mini" style="margin-left: 10px;" @click="Edituserlevel(1,'')">修改客户等级</el-button>
					</buttonPermissions>

					<el-dropdown style="margin-left: 10px;" v-if="isMoreShow">
						<el-button size="mini" v-if="isMoreShow">更多</el-button>
						<el-dropdown-menu slot="dropdown" v-if="isMoreShow">
							<el-dropdown-item>
								<buttonPermissions :datas="'batchGivePoint'">
									<div @click="AddScore(1,'')">赠送积分</div>
								</buttonPermissions>
							</el-dropdown-item>
							<el-dropdown-item>
								<buttonPermissions :datas="'batchDeductPoints'">
									<div @click="ReduceScore(1,'')">扣减积分</div>
								</buttonPermissions>
							</el-dropdown-item>
							<!-- <el-dropdown-item>
							<buttonPermissions :datas="'clearPoints'">
								<div @click="ClearScore">清空积分</div>
								</buttonPermissions>
							</el-dropdown-item> -->
							<el-dropdown-item>
								<buttonPermissions :datas="'batchEditStore'">
									<div @click="EditSelect(0,'',3)">修改归属门店</div>
								</buttonPermissions>
							</el-dropdown-item>
							<!-- <el-dropdown-item>
								<div @click="EditSelect(1,'',3)">修改锁粉店员</div>
							</el-dropdown-item> -->
						</el-dropdown-menu>
						<el-dropdown-menu v-else></el-dropdown-menu>
					</el-dropdown>

				</div>
				<el-pagination v-if="Total" style="margin:20px 0;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>

			</div>
		</div>

		<!-- //设置标签 -->
		<el-dialog title="选择标签" :visible.sync="Labelvisi" width="970px" v-loading="Setlabeloading">
			<div class="selectBox" style="width: 92%;margin: 0px auto;height:400px;overflow:auto;">
				<div class="selectItem" v-for="(item,index) in LabelNames" :key="index">
					<el-checkbox v-model="item.checked" class="checkI" @change="ChangeState(item)"></el-checkbox>
					<span>{{item.MemberTagName}}</span>
				</div>
			</div>
			<div style="text-align: center;margin-top: 20px;">
				<el-button style="width: 120px;" @click="Labelvisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SavememberLable">确认</el-button>
			</div>

		</el-dialog>
		<!-- //修改客户等级 -->
		<el-dialog title="修改会员等级" :visible.sync="Userlevlvisi" width="800px" v-loading="leveloading" :before-close="closeUserlev">
			<div class="Userbox">
				<p class="title">修改会员等级，客户成长值会调整到新等级的初始门槛</p>
				<div style="color: #666;">
					<p>例：VIP1需要300成长值，VIP2需要1000成长值。</p>
					<p>小王是VIP1，当前350个成长值，要调整到VIP2，则小王成长值变为1000，等级变为VIP2。</p>
					<p>小张是VIP2，当前1500个成长值，调整到VIP1之后，小张的成长值变为300。</p>
				</div>
				<div>
					<el-form :model="EditlevelForm" :rules="levelRules" ref="EditlevelForm" label-width="120px" class="demo-ruleForm">
						<el-form-item label="修改会员等级:" prop="userLevel">
							<el-select v-model="EditlevelForm.userLevel" placeholder="请选择" @change="ChangeLevelstate">
								<el-option v-for="item in VipLevelist" :key="item.Value" :label="item.Text" :value="item.Value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="修改归属门店:" prop="forStore" v-if="noUserchange">
							<el-select v-model="EditlevelForm.forStore" placeholder="请选择">
								<el-option v-for="item in storeList" :key="item.Value" :label="item.Text" :value="item.Value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="修改锁粉店员:" prop="Lockcustomer" v-if="noUserchange&&selectmore">
							<div style="display: flex;">
								<div>
									<el-input v-model="EditlevelForm.Lockcustomer" style="width: 300px;" maxlength="11" clearable @clear="clearAddmember(2)"></el-input>
									<div v-if='EmployneeInfo.EmployeeName || EmployneeInfo.WxHeadUrl || EmployneeInfo.ShopNameShow' style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
										<img :src="EmployneeInfo.WxHeadUrl?EmployneeInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
										<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
											<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.EmployeeName}}</div>
											<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.ShopNameShow}}</div>
										</div>
										<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
											<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
										</div>
									</div>
									<div v-if='IsSearch' style="color:#E51C23;">查询不到相关店员数据，请确认输入手机号无误</div>
								</div>
								<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchLock(1)">查询</el-button>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
			<div style="text-align: center;margin-top: 30px;">

				<el-button style="width: 120px;" @click="closeUserlev">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SureditLevel('EditlevelForm')" :loading="Sureleveloading">确认</el-button>
			</div>
		</el-dialog>
		<!-- //赠送积分 -->
		<el-dialog title="赠送积分" :visible.sync="Scorevisi" width="800px" :before-close="closeScoreForm">
			<el-form :model="ScoreForm" :rules="Scorerules" ref="ScoreForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="赠送数量:" prop="Num">
					<el-input v-model="ScoreForm.Num" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[1-9]\d*/)||'';}).call(this);"></el-input>
				</el-form-item>
				<el-form-item label="赠送原因:" prop="Cause">
					<el-input type="textarea" v-model="ScoreForm.Cause" style="width: 350px;" maxlength="150"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top: 30px;">

				<el-button style="width: 120px;" @click="closeScoreForm">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="submitaddSure('ScoreForm')" :loading="Addscoreloading">确认</el-button>
			</div>

		</el-dialog>
		<!-- //扣减积分 -->
		<el-dialog title="扣减积分" :visible.sync="Reducevisi" width="800px" :before-close="closeReduceForm">
			<el-form :model="ReduceForm" :rules="Reducerules" ref="ReduceForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="扣减数量:" prop="Num">
					<el-input v-model="ReduceForm.Num" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/[1-9]\d*/)||'';}).call(this);"></el-input>
				</el-form-item>
				<el-form-item label="扣减原因:" prop="Cause">
					<el-input type="textarea" v-model="ReduceForm.Cause" style="width: 350px;" maxlength="150"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="closeReduceForm">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="ReduceSure('ReduceForm')" :loading="Reducscoreloading">确认</el-button>
			</div>

		</el-dialog>
		<!-- //修改门店和锁粉店员 -->
		<el-dialog :title="EditInfoTitle" :visible.sync="EditInfovisi" width="800px">
			<div v-if="EditInfoTitle=='修改归属门店'">
				<label>修改归属门店：</label>
				<el-select v-model="editStoreId" placeholder="请选择" filterable>
					<el-option v-for="item in storeList" :key="item.Value" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			<div v-else style="display:flex;">
				<label>修改锁粉店员：</label>
				<div style="display: flex;">
					<div>
						<el-input v-model="EditlockCustomer" style="width: 300px;" maxlength="11" clearable @clear='clearAddmember(3)'></el-input>
						<div v-if='EmployneeInfo.EmployeeName || EmployneeInfo.WxHeadUrl || EmployneeInfo.ShopNameShow' style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
							<img :src="EmployneeInfo.WxHeadUrl?EmployneeInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
							<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
								<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.EmployeeName}}</div>
								<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.ShopNameShow}}</div>
							</div>
							<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
								<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
							</div>
						</div>
						<div v-if='IsSearch' style="color:#E51C23;">查询不到相关店员数据，请确认输入手机号无误</div>
					</div>
					<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchLock(3)">查询</el-button>
				</div>
				<!-- <el-select v-model="editLockId" placeholder="请选择" filterable>
					<el-option v-for="item in lockList" :key="item.Id" :label="item.Name" :value="item.Id">
					</el-option>
				</el-select> -->
			</div>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="EditInfovisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SureEdit">确认修改</el-button>
			</div>

		</el-dialog>
		<!-- //积分清零 -->
		<el-dialog title="积分清零" :visible.sync="Scorezerovisi" width="800px">
			<el-form :model="ScorezeroForm" :rules="Scorezerorules" ref="ScorezeroForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="清零原因:" prop="Cause">
					<el-input type="textarea" v-model="ScorezeroForm.Cause" style="width: 350px;"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="Scorezerovisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="ZeroSure('ScorezeroForm')">确认</el-button>
			</div>

		</el-dialog>

		<!-- //添加会员 -->
		<el-dialog title="添加会员" :visible.sync="UserInfoVisi" width="600px" class="eidt_info">
			<el-form :model="UserInfoForm" :rules="userRules" ref="UserInfoForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="姓名:" prop="userName">
					<el-input v-model="UserInfoForm.userName" maxlength="20" placeholder="最多输入20个汉字"></el-input>
				</el-form-item>
				<el-form-item label="手机号:" prop="userPhone">
					<el-input v-model="UserInfoForm.userPhone" maxlength="11" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="生日:" prop="Birthday">
					<el-date-picker v-model="UserInfoForm.Birthday" type="date" placeholder="选择日期" style="width: 300px;" value-format="yyyy-MM-dd"
					 format="yyyy-MM-dd">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="会员级别:" prop="VipLevel">
					<el-select v-model="UserInfoForm.VipLevel" placeholder="请选择">
						<el-option v-for="item in VipLevefilterlist" :key="item.Value" :label="item.Text" :value="item.Value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="归属门店:" prop="VipDoor">
					<el-select v-model="UserInfoForm.VipDoor" placeholder="请选择">
						<el-option v-for="item in storeList" :key="item.Value" :label="item.Text" :value="item.Value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="微信号:">
					<el-input v-model="UserInfoForm.WxId" style="width: 300px;" placeholder="请输入微信号"></el-input>
				</el-form-item>
				<el-form-item label="性别:">
					<el-select v-model="UserInfoForm.Sex" placeholder="请选择">
						<el-option v-for="item in SexList" :key="item.Id" :label="item.Name" :value="item.Id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="地区:">
					<el-cascader style="width: 300px;" :props="props" :options="wePlacelist" clearable @change="handleAddressChange"
					 v-model="AddressArr" />
				</el-form-item>
				<el-form-item label="锁粉店员:" prop="VipLockCustomer">
					<div style="display: flex;">
						<div>
							<el-input v-model="UserInfoForm.VipLockCustomer" style="width: 300px;" maxlength="11" clearable @clear="clearAddmember(1)"></el-input>
							<div v-if='EmployneeInfo.EmployeeName || EmployneeInfo.WxHeadUrl || EmployneeInfo.ShopNameShow' style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
								<img :src="EmployneeInfo.WxHeadUrl?EmployneeInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
								<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.EmployeeName}}</div>
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.ShopNameShow}}</div>
								</div>
								<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
									<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
								</div>
							</div>
							<div v-if='IsSearch' style="color:#E51C23;">查询不到相关店员数据，请确认输入手机号无误</div>

						</div>
						<!-- @click="goodsFilter" -->
						<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchLock(2)">查询</el-button>
					</div>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="UserInfoVisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SaveUserInfo('UserInfoForm')" :loading="SureAddloading">确认添加</el-button>
			</div>

		</el-dialog>


		<!-- //授权推手 -->
		<el-dialog title="授权推手" :visible.sync="DistrimemVisi" width="600px" class="eidt_info" v-loading="Distrimemloading">
			<el-form :model="DistrimemForm" :rules="DistrimemRules" ref="DistrimemForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="姓名:" prop="userName">
					<el-input v-model="DistrimemForm.userName" maxlength="20" placeholder="最多输入20个字" :disabled="originNameValue!=''"></el-input>
				</el-form-item>
				<el-form-item label="手机号:" prop="userPhone">
					<el-input v-model="DistrimemForm.userPhone" maxlength="11" placeholder="请输入手机号" :disabled="originPhoneValue!=''"></el-input>
				</el-form-item>
				<el-form-item label="推手等级:" prop="Distrilevel">
					<el-select v-model="DistrimemForm.Distrilevel" placeholder="请选择">
						<el-option v-for="item in Distrilevelist" :key="item.Id" :label="item.DistributRoleName" :value="item.Id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="推荐人:">
					<div style="display: flex;">
						<div>
							<el-input v-model="DistrimemForm.Recomend" style="width: 300px;" maxlength="11" placeholder="输入推荐人手机号，点击右侧查询"></el-input>
							<div v-if='RecomendInfo.EmployeeName || RecomendInfo.WxHeadUrl || RecomendInfo.DistributRoleName' style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
								<img :src="RecomendInfo.WxHeadUrl?RecomendInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
								<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{RecomendInfo.EmployeeName}}</div>
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{RecomendInfo.DistributRoleName}}</div>
								</div>
								<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
									<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
								</div>
							</div>
							<div v-if='IsrecomendSearch' style="color:#E51C23;">查询不到相关推手信息</div>
						</div>
						<!-- @click="goodsFilter" -->
						<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchRecomend">查询</el-button>
					</div>
				</el-form-item>

				<el-form-item label="锁粉店员:" prop="VipLockCustomer">
					<div style="display: flex;">
						<div>
							<el-input v-model="DistrimemForm.VipLockCustomer" style="width: 300px;" maxlength="11" clearable @clear='clearAddmember(4)'></el-input>
							<div v-if='DistrimemForm.LockMallEmployeeId>0' style="border: 1px solid #67C23A;height: 60px;margin-left: 5px;margin-top: 10px;display: flex;align-items: center;position: relative;">
								<img :src="EmployneeInfo.WxHeadUrl?EmployneeInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
								<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.EmployeeName}}</div>
									<div style="font-size: 14px;color: #303133;line-height: 25px;">{{EmployneeInfo.ShopNameShow}}</div>
								</div>
								<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
									<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
								</div>
							</div>
							<div v-if='IsSearch' style="color:#E51C23;">查询不到相关店员数据，请确认输入手机号无误</div>
						</div>
						<!-- @click="goodsFilter" -->
						<el-button type="primary" style="margin-left:10px;width:70px;height: 35px;" @click="SearchLock(4)">查询</el-button>
					</div>
				</el-form-item>
				<!-- <el-form-item label="微信标识:">
					<el-input v-model="DistrimemForm.WxId" style="width: 300px;" placeholder="请在客户详情页中复制该推手的微信标识，并粘贴在此处" :disabled="originWeixinId!=''"></el-input>
				</el-form-item> -->



			</el-form>
			<div style="text-align: center;margin-top: 30px;">
				<el-button style="width: 120px;" @click="DistrimemVisi=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;margin-left:25px;" @click="SaveDistriInfo('DistrimemForm')" :loading="SaveDistriloading">确认授权</el-button>
			</div>

		</el-dialog>

		<!-- 修改锁粉推手弹框 -->
		<el-dialog title="修改锁粉推手" :visible.sync="editeLockpushVisi" width="600px" v-loading='loading'>
			<el-form :model="lockpushForm" ref="lockpushForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="修改锁粉推手:">
					<el-input v-model="lockpushForm.lockpushName" clearable style="width:250px" @clear='clearlockInfo'></el-input>
					<el-button type="primary" style="margin-left:30px;width:90px;" @click="lockpushSearch">查询</el-button>
					<div v-if='lockpushForm.FirstFromId >0' style="border: 1px solid #67C23A;height: 60px;width:250px;margin-top: 10px;display: flex;align-items: center;position: relative;">
						<img :src="lockpushInfo.WxHeadUrl?lockpushInfo.WxHeadUrl:defaultHeader" alt="" style="width: 50px;height: 50px;margin-left: 10px;border-radius: 100%;">
						<div style="font-size: 14px;color: #303133;height: 50px;margin-left: 10px;flex: 1;">
							<div style="font-size: 14px;color: #303133;line-height: 25px;">{{lockpushInfo.EmployeeName}}</div>
							<div style="font-size: 14px;color: #303133;line-height: 25px;">{{lockpushInfo.DistributRoleName}}</div>
						</div>
						<div style="width: 20px;position: absolute;height: 20px;background:#67C23A;top: 0;right: 0;">
							<i class="el-icon-check" style="color:#fff;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"></i>
						</div>
					</div>
					<div v-if='IsSearch' style="color:#E51C23;">查询不到相关推手信息，请确认输入手机号无误</div>

				</el-form-item>

				<div style="text-align: center;margin-top: 30px;">
					<el-button style="width: 120px;" @click="editeLockpushVisi=false">关闭</el-button>
					<el-button type="primary" style="width: 120px;margin-left:25px;" @click="Savelockpush" :loading="lockpushLoading">确认修改</el-button>
				</div>
			</el-form>
		</el-dialog>

	</div>
</template>

<script>
	import config from '@/config/index';
	import axios from 'axios'
	import buttonPermissions from '@/components/buttonPermissions';
	import {
		MemberInit,
		MemberList,
		MemberTaglists,
		MemberUserlevels,
		SearchLockEmployee,
		SaveEmployeenInfo,
		SingelChangetags,
		MemberIDtags,
		EditsomeTags,
		ChangelevelInit,
		Savesingelevel,
		SavesomeViplevels,
		Changesingelpoint,
		Changesomepoints,
		ChangesingelShop,
		ChangesomeShops,
		ChangesingelLock,
		filterBoxList,
		findRecommendMember,
		authDistributorInit,
		SaveauthDistributor,
		changeFirstFromMemberInit,
		changeFirstFromMember
	} from '@/api/TurnTomySelf'
	import {
		mapGetters
	} from 'vuex'
	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				
				authorizeList: [{
						Id: 0,
						value: '未授权'
					},
					{
						Id: 1,
						value: '已授权'
					}
				],
				IsSearchpush: false,
				lockpushInfo: {
					WxHeadUrl: '',
					EmployeeName: '',
					DistributRoleName: '',
				},
				lockpushForm: {
					lockpushName: '', //查询的手机号
					FirstFromId: 0, //查询到的推手Id
					memberId: 0, //客户id
				},
				lockpushLoading: false,
				lockpushRules: {},
				editeLockpushVisi: false,
				IsfilterShow: false,
				btnText: '展开更多筛选条件',
				isMoreShow: true,
				defaultHeader: config.DEFAULT_HEADER,
				originNameValue: '',
				originPhoneValue: '',
				originWeixinId: '',
				Distrimemloading: false,
				SaveDistriloading: false,
				RecomendInfo: {
					EmployeeName: '',
					WxHeadUrl: '',
					DistributRoleName: '',
				},
				//推手信息
				Distrilevelist: [],
				IsrecomendSearch: false,
				DistrimemVisi: false,
				DistrimemForm: {
					userName: '',
					userPhone: '',
					Distrilevel: '',
					Recomend: '',
					RecomendId: '',
					VipLockCustomer: '',
					LockMallEmployeeId: '',
					WxId: '',
				},
				DistrimemRules: {
					userName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}, ],
					userPhone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, ],
					Distrilevel: [{
						required: true,
						message: '请选择推手级别',
						trigger: 'change'
					}, ],
				},
				EditlevelIndex: 0, //多选还是单选
				leveloading: false, //初始化等级数据
				Sureleveloading: false,
				originLevel: null,
				isLevelFounds: false,
				noUserchange: false,
				selectmore: false, //多选锁粉店员
				//会员等级修改
				EditlevelForm: {
					userLevel: '',
					forStore: '',
					Lockcustomer: '',
					LockMallEmployeeId: '',
				},
				levelRules: {
					userLevel: [{
						required: true,
						message: '请修改会员等级',
						trigger: 'change'
					}, ],
					forStore: [{
						required: true,
						message: '请修改归属门店',
						trigger: 'change'
					}, ],
					// 					Lockcustomer: [{
					// 						required: true,
					// 						message: '请修改锁粉店员',
					// 						trigger: 'blur'
					// 					}, ],
				},
				Setlabeloading: false,
				memberLabelIndex: 0,
				//商户id
				MemberShopid: '',
				LabelId: '',
				LabelIds: [],
				LabelNames: [], //标签名称
				isLongFounds: false,
				SureAddloading: false,
				IsSearch: false,
				EmployneeInfo: {
					EmployeeName: '',
					WxHeadUrl: '',
					ShopNameShow: '',
				},
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
				ruleForm: {
					authorize: null,
					MemberTypeId: 1,
					lastTime: [],
					CustomTime: [],
					UsersTime: [],
					DistriTime: [],
					identityIds: [],
					labelIds: [],
					StyleIds: [],
					SexId: null,
					wePlaceIds: [],
					storeId: null,
					lockId: null,
					searchKey: '',
					minScore: '',
					maxScore: '',
					minPay: '',
					maxpay: '',
					minPrice: '',
					maxPrice: '',
					mintotalPrice: '',
					maxtotalPrice: ''
				},
				currentIndex: 0,
				//客户概况数据
				CusTypesList: [],
				identityList: [],
				labelList: [],
				StyleList: [{
						Id: 1,
						Name: '下单'
					}, {
						Id: 2,
						Name: '关注公众号'
					}, {
						Id: 3,
						Name: '领取优惠券'
					}, {
						Id: 4,
						Name: '开通会员'
					}, {
						Id: 5,
						Name: '咨询客服'
					}, {
						Id: 6,
						Name: '手动添加'
					},
					{
						Id: 7,
						Name: '接口添加'
					}
				],
				SexList: [{
					Id: 1,
					Name: '男'
				}, {
					Id: 2,
					Name: '女'
				}, {
					Id: 3,
					Name: '保密'
				}],
				wePlacelist: [],
				storeList: [],
				lockList: [],
				groupData: [],
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				OrderBy: 'AddTime',
				IsAsc: 0,
				multipleSelection: [],
				checked: false,
				loading: false,
				Labelvisi: false,
				selectCheck: false,
				Userlevlvisi: false,
				MemberLevelist: [],
				MemberId: null,
				ScoreTitle: '赠送',
				Scorevisi: false,
				//积分数据
				Addscoreloading: false,
				AddscoreIndex: 0,
				ScoreForm: {
					Num: '',
					Cause: ''
				},
				Scorerules: {
					Num: [{
						required: true,
						message: '请输入赠送数量',
						trigger: 'blur'
					}, ],
					Cause: [{
						required: true,
						message: '请输入赠送原因',
						trigger: 'blur'
					}, ]
				},
				ReductscoreIndex: 0,
				Reducscoreloading: false,
				Reducerules: {
					Num: [{
						required: true,
						message: '请输入扣减数量',
						trigger: 'blur'
					}, ],
					Cause: [{
						required: true,
						message: '请输入扣减原因',
						trigger: 'blur'
					}, ]
				},
				Reducevisi: false,
				ReduceForm: {
					Num: '',
					Cause: ''
				},
				EditInfoTitle: '修改归属门店',
				EditInfovisi: false,
				//单独修改门店和锁粉
				editStoreId: '',
				EditlockCustomer: '',
				LockMallEmployeeId: '',

				Scorezerovisi: false,
				ScorezeroForm: {
					Cause: ''
				},
				Scorezerorules: {
					Cause: [{
						required: true,
						message: '请输入清零原因',
						trigger: 'blur'
					}]
				},
				UserInfoVisi: false,
				UserInfoForm: {
					userName: '',
					userPhone: '',
					Sex: '',
					ProvinceId: '',
					CityId: '',
					Province: '',
					City: '',
					Birthday: '',
					WxId: '',
					VipLevel: '',
					VipDoor: '',
					VipLockCustomer: '',
					LockMallEmployeeId: '',
				},
				AddressArr: [],
				VipLevelist: [],
				VipLevefilterlist: [],
				userRules: {
					userName: [{
						required: true,
						message: '请输入会员姓名',
						trigger: 'blur'
					}, ],
					userPhone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, ],
					Birthday: [{
						required: true,
						message: '请选择生日',
						trigger: 'change'
					}],
					VipLevel: [{
						required: true,
						message: '请选择客户等级',
						trigger: 'change'
					}, ],
					VipDoor: [{
						required: true,
						message: '请选择归属门店',
						trigger: 'change'
					}, ],
					// 					VipLockCustomer: [{
					// 						required: true,
					// 						message: '请输入锁粉店员',
					// 						trigger: 'blur'
					// 					}, ]
				},
				EditStoreType: null,
				EditsingelSome: null,
				oldPhone: '',
				goUrls: config.GO_URL,
				isCurrentAll: false,
				checkedAll:false,
			}
		},
		computed: {
			...mapGetters([
				'routerList'
			]),
			// checkedAll() {
			// 	console.log(this.multipleSelection, '当前页被选中的')
			// }
		},
		created() {
			this.getDataList()
			this.getMemberInit()
			this.getUserlevels() //获取所有会员等级
			this.getMemberTaglists() //获取所有标签名称
			this.getfilterBoxList() //获取推手级别
		},
		beforeMount() {

			//获取列表数据
			if (this.$route.query.Id) {
				this.ruleForm.lockId = String(this.$route.query.Id)
			} else {
				this.ruleForm.lockId = null
			}
			//获取标签列表数据
			if (this.$route.query.customerTagId) {
				this.ruleForm.labelIds = [String(this.$route.query.customerTagId)]
			} else {
				this.ruleForm.labelIds = []
			}

			this.getMemberlist()
			this.getRouterList()
		},
		methods: {
			//授权推手(//初始化授权信息)
			Authbutor(record) {
				this.IsSearch = false;
				this.DistrimemVisi = true

				this.MemberShopid = record.Id
				this.getauthDistributorInit()
				//清除验证

				// authDistributorInit
			},
			// lockpushForm: {
			// 	lockpushName: '',  //查询的手机号
			// 	FirstFromId:null,  //查询到的推手Id
			// 	memberId:null, //客户id
			// },
			// 修改锁粉推手
			editLockpush(record) {
				this.IsSearch = false
				this.editeLockpushVisi = true
				this.lockpushForm.memberId = record.Id //客户id
				this.getFirstFromMemberInit(record.Id)
			},
			async getFirstFromMemberInit(memberId) {
				try {
					this.loading = true
					let data = {
						Id: memberId
					}
					let result = await changeFirstFromMemberInit(data)
					if (result.IsSuccess) {
						this.lockpushInfo.EmployeeName = result.Result.EmployeeName
						this.lockpushInfo.WxHeadUrl = result.Result.WxHeadUrl
						this.lockpushInfo.DistributRoleName = result.Result.DistributRoleName
						this.lockpushForm.FirstFromId = result.Result.Id
						this.lockpushForm.lockpushName = result.Result.Phone
						this.oldPhone = result.Result.Phone
						if (this.lockpushForm.FirstFromId == 0) {
							this.IsSearch = false
						}
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			// 确认修改
			async Savelockpush() {
				try {
					this.lockpushLoading = true
					if (this.lockpushForm.lockpushName != this.oldPhone && this.lockpushForm.lockpushName != '') {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请点击[查询]确认推手信息'
						});
						return false
					}
					if (this.IsSearch) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入正确的推手信息'
						});
						return false
					}
					// 先注释着
					// if (!this.DistrimemForm.LockMallEmployeeId) {
					// 	this.$message({showClose: true,
					// 		type: 'error',
					// 		message: '请输入正确的锁粉信息'
					// 	});
					// 	return false
					// }
					let data = {
						Id: this.lockpushForm.memberId,
						FirstFromId: this.lockpushForm.FirstFromId,
					}
					let result = await changeFirstFromMember(data)
					if (result.IsSuccess) {
						this.$message({
								showClose: true,
								type: 'success',
								message:'修改成功'
						});
						this.editeLockpushVisi  = false
						this.getMemberlist()
					}
					// if(!this.IsSearchpush&&this.lockpushForm.lockpushName){
					// 	this.$message({
					// 		showClose: true,
					// 		type: 'error',
					// 		message: '请点击[查询]确认员工信息'
					// 	});
					// 	return
					// }

				} catch (e) {
					//TODO handle the exception
				} finally {
					this.lockpushLoading = false
				}

			},
			handlefilteClick() {
				this.IsfilterShow = !this.IsfilterShow
				if (this.IsfilterShow) {
					this.btnText = '收起更多筛选条件'
				} else {
					this.btnText = '展开更多筛选条件'
				}

			},
			//清楚查询推手的手机号
			clearlockInfo() {
				console.log('没有触发清楚事件吗')
				this.lockpushInfo.WxHeadUrl = ''
				this.lockpushInfo.EmployeeName = ''
				this.lockpushInfo.DistributRoleName = ''
				this.lockpushForm.FirstFromId = 0
				if (this.IsSearch) {
					this.IsSearch = false
				}
			},
			clearAddmember(index) {
				if (this.EmployneeInfo.EmployeeName || this.EmployneeInfo.WxHeadUrl || this.EmployneeInfo.ShopNameShow) {
					this.EmployneeInfo.EmployeeName = ''
					this.EmployneeInfo.WxHeadUrl = ''
					this.EmployneeInfo.ShopNameShow = ''
					if (index == 1) {
						this.UserInfoForm.LockMallEmployeeId = 0
					}
					if (index == 2) {
						this.EditlevelForm.LockMallEmployeeId = 0
					}
					if (index == 3) {
						this.LockMallEmployeeId = 0
					}
					if (index == 4) {
						this.DistrimemForm.LockMallEmployeeId = 0
					}
				}
				if (this.IsSearch) {
					this.EmployneeInfo.EmployeeName = ''
					this.EmployneeInfo.WxHeadUrl = ''
					this.EmployneeInfo.ShopNameShow = ''
					this.IsSearch = false
				}

				console.log('触发清空888888')
			},
			getRouterList() {
				if (this.routerList.some(item => {
						return item == 'batchGivePoint' || item == 'batchDeductPoints' || item == 'batchEditStore'
					})) {
					this.isMoreShow = true
				} else {
					this.isMoreShow = false
				}
				if (this.routerList.some(item => {
						return item == 'changeLevel' || item == 'givePoint' || item == 'deductPoints' || item == 'editStoredoor' || item ==
							'editLockStaff'
					})) {
					this.isMoreShow1 = true
				} else {
					this.isMoreShow1 = false
				}

			},
			//保存推手的信息
			SaveDistriInfo(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.SaveDistriloading = true
						try {
							if (this.DistrimemForm.VipLockCustomer != this.oldPhone && this.DistrimemForm.VipLockCustomer != '') {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请点击[查询]确认员工信息'
								});
								return false
							}
							if (this.IsSearch) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请输入正确的锁粉信息'
								});
								return false
							}
							// 先注释着
							// if (!this.DistrimemForm.LockMallEmployeeId) {
							// 	this.$message({showClose: true,
							// 		type: 'error',
							// 		message: '请输入正确的锁粉信息'
							// 	});
							// 	return false
							// }
							let data = {
								Id: this.MemberShopid,
								Phone: this.DistrimemForm.userPhone,
								MemberDistributorRoleId: this.DistrimemForm.Distrilevel,
								MemberDistributorRecommendId: this.DistrimemForm.RecomendId,
								LockMallEmployeeId: this.DistrimemForm.LockMallEmployeeId,
								UnionId: this.DistrimemForm.WxId
							}
							let result = await SaveauthDistributor(data)
							if (result.IsSuccess) {
								this.DistrimemVisi = false
								this.getMemberlist()
							}
						} catch (err) {

						} finally {
							this.SaveDistriloading = false
						}
					} else {
						return false;
					}
				})

			},
			//推荐人查询
			async SearchRecomend() {
				console.log(this.DistrimemForm.Recomend, '查询推荐人执行6666')
				try {
					if (!this.DistrimemForm.Recomend) {
						this.$message({
							showClose: true,
							message: '请输入推荐人信息',
							type: 'error'
						});
						return
					}
					let data = {
						Phone: this.DistrimemForm.Recomend
					}
					let result = await findRecommendMember(data)
					if (result.IsSuccess) {
						this.RecomendInfo = result.Result
						if (JSON.stringify(this.RecomendInfo) == "{}") {
							this.IsrecomendSearch = true
						} else {
							this.IsrecomendSearch = false
						}
						//推荐人Id
						this.DistrimemForm.RecomendId = this.RecomendInfo.Id
					}
				} catch (err) {

				} finally {

				}

			},
			async getfilterBoxList() {
				try {
					let result = await filterBoxList({})
					this.Distrilevelist = result.Result
					console.log(this.Distrilevelist, '推手级别列表')
				} catch (err) {

				} finally {

				}
			},
			async getauthDistributorInit() {
				this.Distrimemloading = true
				try {
					let data = {
						Id: this.MemberShopid
					}
					let result = await authDistributorInit(data)
					if (result.IsSuccess) {
						this.originNameValue = result.Result.NameValue //初始化姓名
						this.originPhoneValue = result.Result.Phone //初始化手机号
						this.originWeixinId = result.Result.UnionId //初始化微信标识
						this.DistrimemForm.userName = result.Result.NameValue
						this.DistrimemForm.userPhone = result.Result.Phone
						this.DistrimemForm.WxId = result.Result.UnionId
						if (result.Result.MemberDistributorRoleId == 0) {
							result.Result.MemberDistributorRoleId = ''
						}
						this.DistrimemForm.Distrilevel = result.Result.MemberDistributorRoleId //分销级别

						if (result.Result.LockMallEmployeePhone) {
							this.DistrimemForm.VipLockCustomer = result.Result.LockMallEmployeePhone // 锁粉手机号
						} else {
							this.DistrimemForm.VipLockCustomer = ''
						}
						this.oldPhone = this.DistrimemForm.VipLockCustomer
						this.DistrimemForm.LockMallEmployeeId = result.Result.LockMallEmployeeId //锁粉Id
						if (this.DistrimemForm.LockMallEmployeeId == 0) {
							this.IsSearch = false
						}
						this.EmployneeInfo.EmployeeName = result.Result.LockMallEmployeeName
						this.EmployneeInfo.WxHeadUrl = result.Result.LockMallEmployeeWxHeadUrl
						this.EmployneeInfo.ShopNameShow = result.Result.LockMallEmployeeShopName
						this.DistrimemForm.RecomendId = result.Result.MemberDistributorRecommendId //推荐人Id
						if (this.DistrimemForm.RecomendId == 0) {
							console.log(this.DistrimemForm.RecomendId, '没有执行吗111111')
							this.IsrecomendSearch = false
						}

						if (result.Result.MemberDistributorRecommendPhone) {
							this.DistrimemForm.Recomend = result.Result.MemberDistributorRecommendPhone
						} else {
							this.DistrimemForm.Recomend = ''
						}
						this.RecomendInfo.EmployeeName = result.Result.MemberDistributorRecommendName
						this.RecomendInfo.WxHeadUrl = result.Result.MemberDistributorRecommendWxHeadUrl
						// MemberDistributorRecommendShopName
						this.RecomendInfo.DistributRoleName = result.Result.DistributRoleName
						this.$nextTick(function() {
							this.$refs.DistrimemForm.clearValidate()
						})
					}
				} catch (err) {

				} finally {
					this.Distrimemloading = false
				}
			},
			//确认修改
			async SureEdit() {
				// index 0  归属门店 1修改锁粉  location 2 单选 3多选
				try {
					//归属门店
					if (this.EditStoreType == 0) {
						//归属门店单选
						if (this.EditsingelSome == 2) {
							let data = {
								Id: this.MemberShopid,
								VipBelongShopId: this.editStoreId
							}
							let result = await ChangesingelShop(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									message: '修改归属门店成功',
									type: 'success'
								});
								this.EditInfovisi = false
								this.getMemberlist()
							}
						}
						//归属门店多选
						else {
							if (this.editStoreId == '') {
								this.$message({
									showClose: true,
									message: '请选择归属门店',
									type: 'error'
								});
								return false
							}
							let ids = this.multipleSelection.map(item => {
								return item.Id
							})
							let data = {
								MemberIdList: ids,
								VipBelongShopId: this.editStoreId
							}
							let result = await ChangesomeShops(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									message: '批量修改归属门店成功',
									type: 'success'
								});
								this.EditInfovisi = false
								this.getMemberlist()
							}

						}


					}
					//修改锁粉
					else {
						//单独修改
						if (this.EditsingelSome == 2) {

							if (this.EditlockCustomer != this.oldPhone && this.EditlockCustomer != '') {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请点击[查询]确认员工信息'
								});
								return false
							}
							// 							if (this.IsSearch) {
							// 								this.$message({showClose: true,
							// 									type: 'error',
							// 									message: '请输入正确的锁粉信息'
							// 								});
							// 								return false
							// 							}
							let data = {
								Id: this.MemberShopid,
								LockMallEmployeeId: this.LockMallEmployeeId
							}
							let result = await ChangesingelLock(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									message: '修改锁粉店员成功',
									type: 'success'
								});
								this.EditInfovisi = false
								this.getMemberlist()
							}
						}
					}
				} catch (err) {

				} finally {

				}

			},
			EditSelect(index, record, location) {
				this.EditStoreType = index //门店还是锁粉
				this.EditsingelSome = location //单选还是多选
				// index 0  归属门店 1修改锁粉  location 2 单选 3多选
				//归属门店
				if (index == 0) {
					this.EditInfoTitle = '修改归属门店'
					//多选
					if (location == 3) {
						//多选初始化清空
						this.editStoreId = ''
						if (this.multipleSelection.length == 0) {
							this.$message({
								showClose: true,
								message: '请选择修改归属门店的客户',
								type: 'error'
							});
							return false
						}
						//多选成功
						this.EditInfovisi = true

					}
					//单选归属门店
					if (location == 2) {
						this.MemberShopid = record.Id
						this.EditInfovisi = true
						this.getChangelevelInit()
					}
				}
				//修改锁粉店员
				if (index == 1) {
					this.EditInfoTitle = '修改锁粉店员'
					this.IsSearch = false
					// if (location == 3) {
					// 	if (this.multipleSelection.length == 0) {
					// 		this.$message({showClose: true,
					// 			message: '请选择修改锁粉店员的客户',
					// 			type: 'error'
					// 		});
					// 		return false
					// 	}
					// 	this.EditlockCustomer = ''
					// 	this.LockMallEmployeeId = ''
					// 	this.EmployneeInfo.EmployeeName = ''
					// 	this.EmployneeInfo.WxHeadUrl = ''
					// 	this.EmployneeInfo.ShopNameShow = ''
					// 	this.EditInfovisi = true

					// }
					//单选
					if (location == 2) {
						this.MemberShopid = record.Id
						this.EditInfovisi = true
						this.getChangelevelInit()
					}
				}
			},
			//修改客户等级
			Edituserlevel(index, record) {
				this.EditlevelIndex = index
				this.IsSearch = false
				// 0单选,1多选
				if (index == 1) {
					console.log(this.multipleSelection, '是否执行了这里')
					if (this.multipleSelection.length == 0) {
						this.$message({
							showClose: true,
							message: '请选择修改等级的客户',
							type: 'error'
						});
						return false
					}
					//非会员
					let outsider = this.multipleSelection.filter(item => {
						return item.MemberVipRoleId == 0
					})
					//会员
					let users = this.multipleSelection.filter(item => {
						return item.MemberVipRoleId > 0
					})
					if (outsider.length && outsider.length != this.multipleSelection.length || users.length && users.length != this.multipleSelection
						.length) {
						this.$message({
							showClose: true,
							message: '批量修改等级，会员与非会员不可同时修改',
							type: 'error'
						});
						return false
					}
					this.EditlevelForm.userLevel = null
					this.EditlevelForm.forStore = ''
					this.EditlevelForm.Lockcustomer = ''
					this.EditlevelForm.LockMallEmployeeId = ''
					this.EmployneeInfo.EmployeeName = ''
					this.EmployneeInfo.WxHeadUrl = ''
					this.EmployneeInfo.ShopNameShow = ''
					this.noUserchange = false
					this.selectmore = false
					if (outsider.length) {
						this.originLevel = outsider[0].MemberVipRoleId
					}
					this.Userlevlvisi = true
				} else {
					//客户Id
					this.MemberShopid = record.Id ? record.Id : 0
					this.getChangelevelInit()
					this.Userlevlvisi = true
				}



			},
			closeUserlev() {
				this.Userlevlvisi = false
				this.$nextTick(function() {
					this.$refs.EditlevelForm.resetFields()
				})
			},
			//确认修改等级
			SureditLevel(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.Sureleveloading = true
						try {
							//显示锁粉店员
							if (this.noUserchange && this.selectmore) {
								//修改等级查询
								if (this.EditlevelForm.Lockcustomer != this.oldPhone && this.EditlevelForm.Lockcustomer != '') {
									this.$message({
										showClose: true,
										type: 'error',
										message: '请点击[查询]确认员工信息'
									});
									this.Sureleveloading = false
									return false
								}
								if (this.IsSearch) {
									this.$message({
										showClose: true,
										type: 'error',
										message: '请输入正确的锁粉信息'
									});
									return false
								}
								// 								if (!this.EditlevelForm.LockMallEmployeeId) {
								// 									this.$message({showClose: true,
								// 										type: 'error',
								// 										message: '请输入正确的锁粉信息'
								// 									});
								// 									this.Sureleveloading = false
								// 									return false
								// 								}
							}
							//单选修改等级
							if (this.EditlevelIndex == 0) {
								let data = {
									Id: this.MemberShopid,
									MemberVipRoleId: this.EditlevelForm.userLevel,
									VipBelongShopId: this.EditlevelForm.forStore,
									LockMallEmployeeId: this.EditlevelForm.LockMallEmployeeId
								}
								let result = await Savesingelevel(data)
								if (result.IsSuccess) {
									this.$message({
										showClose: true,
										type: 'success',
										message: '修改等级成功'
									});

									this.Userlevlvisi = false
									this.getMemberlist()
								}
							}
							//批量修改等级
							else {
								let MemberIdList = this.multipleSelection.map(item => {
									return item.Id
								})
								let data = {
									MemberIdList: MemberIdList,
									MemberVipRoleId: this.EditlevelForm.userLevel,
									VipBelongShopId: this.EditlevelForm.forStore,
									LockMallEmployeeId: this.EditlevelForm.LockMallEmployeeId
								}
								let result = await SavesomeViplevels(data)
								if (result.IsSuccess) {
									this.$message({
										showClose: true,
										type: 'success',
										message: '批量修改等级成功'
									});
									this.Userlevlvisi = false
									this.getMemberlist()
								}
							}


						} catch (err) {

						} finally {
							this.Sureleveloading = false
						}
					} else {
						return false;
					}
				})
			},
			//非会员变为会员才能修改门店和锁粉店员
			ChangeLevelstate() {
				if (this.originLevel == 0 && Number(this.EditlevelForm.userLevel) > 0) {
					this.noUserchange = true
					//单选
					if (this.EditlevelIndex == 0) {
						this.selectmore = true
					} else {
						this.selectmore = false
					}
				} else {
					this.noUserchange = false
				}
			},
			//初始化等级信息
			async getChangelevelInit() {
				try {
					this.leveloading = true
					let data = {
						Id: this.MemberShopid
					}
					let result = await ChangelevelInit(data)
					console.log(result, '归属门店Id')
					if (result.IsSuccess) {
						//初始化原始等级
						this.noUserchange = false
						// if(result.Result.MemberVipRoleId == 0){
						// 	result.Result.MemberVipRoleId = ''
						// }
						if (result.Result.VipBelongShopId == 0) {
							result.Result.VipBelongShopId = ''
						}
						this.originLevel = result.Result.MemberVipRoleId
						this.editStoreId = String(result.Result.VipBelongShopId)
						this.EditlevelForm.userLevel = String(result.Result.MemberVipRoleId)
						this.EditlevelForm.forStore = String(result.Result.VipBelongShopId)
						this.EditlevelForm.LockMallEmployeeId = result.Result.LockMallEmployeeId
						if (this.EditlevelForm.LockMallEmployeeId == 0) {
							this.IsSearch = false
						}
						this.EditlevelForm.Lockcustomer = result.Result.LockMallEmployeePhone
						//原始的锁粉手机号
						this.oldPhone = result.Result.LockMallEmployeePhone
						console.log(this.oldPhone, '是否修改锁粉店员手机号')
						//锁粉信息
						this.EmployneeInfo.EmployeeName = result.Result.LockMallEmployeeName
						this.EmployneeInfo.WxHeadUrl = result.Result.LockMallEmployeeWxHeadUrl
						this.EmployneeInfo.ShopNameShow = result.Result.LockMallEmployeeShopName
						// this.IsSearch = true
						//单独锁粉店员
						this.LockMallEmployeeId = result.Result.LockMallEmployeeId
						this.EditlockCustomer = result.Result.LockMallEmployeePhone
					}
				} catch (err) {

				} finally {
					this.leveloading = false
				}
			},
			//改变状态值
			ChangeState(record) {
				this.LabelNames = this.LabelNames.map(item => {
					if (item.MemberTagName == record.Text) {
						item.checked = record.checked
					}
					return item
				})
			},
			//获取所有标签名称
			async getMemberTaglists() {
				try {
					let data = {
						TagType: 0
					}
					let result = await MemberTaglists(data)
					if (result.IsSuccess) {
						this.LabelNames = result.Result.Results
					}
				} catch (err) {

				} finally {

				}
			},
			SaveUserInfo(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.SureAddloading = true
						try {
							if (this.UserInfoForm.VipLockCustomer != this.oldPhone && this.UserInfoForm.VipLockCustomer != '') {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请点击[查询]确认员工信息'
								});
								return false
							}
							if (this.IsSearch) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请输入正确的锁粉信息'
								});
								return false
							}
							// 							if (!this.UserInfoForm.LockMallEmployeeId) {
							// 								this.$message({showClose: true,
							// 									type: 'error',
							// 									message: '请输入正确的锁粉信息'
							// 								});
							// 								return false
							// 							}
							const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
							if (!reg.test(this.UserInfoForm.userPhone)) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请输入正确的手机号！'
								});
								return
							}
							let data = {
								Id: 0,
								Name: this.UserInfoForm.userName,
								Phone: this.UserInfoForm.userPhone,
								Birthday: this.UserInfoForm.Birthday,
								MemberVipRoleId: this.UserInfoForm.VipLevel,
								VipBelongShopId: this.UserInfoForm.VipDoor,
								WeixinId: this.UserInfoForm.WxId,
								Gender: this.UserInfoForm.Sex,
								ProvinceId: this.UserInfoForm.ProvinceId,
								CityId: this.UserInfoForm.CityId,
								Province: this.UserInfoForm.Province,
								City: this.UserInfoForm.City,
								LockMallEmployeeId: this.UserInfoForm.LockMallEmployeeId
							}
							let result = await SaveEmployeenInfo(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.UserInfoVisi = false;
								this.getMemberlist()

							}
						} catch (err) {

						} finally {
							this.SureAddloading = false
						}
					} else {
						return false;
					}
				});
			},
			//添加会员
			AddMember() {
				this.IsSearch = false
				this.VipLevefilterlist = this.VipLevelist.filter(item => {
					return item.Value != 0
				})
				this.UserInfoVisi = true
				this.$nextTick(function() {
					this.$refs.UserInfoForm.resetFields();
				})
				this.UserInfoForm.Sex = ''
				this.AddressArr = []
				this.UserInfoForm.VipLockCustomer = ''
				this.oldPhone = this.UserInfoForm.VipLockCustomer
				this.EmployneeInfo.EmployeeName = ''
				this.EmployneeInfo.WxHeadUrl = ''
				this.EmployneeInfo.ShopNameShow = ''
				this.IsSearch = false

			},
			// 锁粉推手查询
			async lockpushSearch() {
				try {
					if (!this.lockpushForm.lockpushName) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入推手信息'
						});
						return
					}
					let data = {
						Phone: this.lockpushForm.lockpushName
					}
					// findRecommendMember
					let result = await findRecommendMember(data)
					if (result.IsSuccess) {
						this.lockpushInfo = result.Result
						if (JSON.stringify(this.lockpushInfo) == "{}") {
							this.IsSearch = true
						} else {
							this.IsSearch = false
						}
						this.lockpushForm.FirstFromId = this.lockpushInfo.Id
						this.oldPhone = this.lockpushForm.lockpushName

					}
				} catch (err) {

				} finally {

				}

			},

			//查询锁粉店员
			async SearchLock(index) {
				//1  修改等级里面   2//添加会员的   3//单个操作锁粉店员
				try {
					let data = {}
					//添加会员里面的
					if (index == 2) {
						// this.isLongFounds = true;
						if (!this.UserInfoForm.VipLockCustomer) {
							this.$message({
								showClose: true,
								message: '请输入锁粉店员信息',
								type: 'error'
							});
							return
						}
						data = {
							Phone: this.UserInfoForm.VipLockCustomer
						}
					}
					//修改等级里面的
					if (index == 1) {
						// this.isLongFounds = true
						if (!this.EditlevelForm.Lockcustomer) {
							this.$message({
								showClose: true,
								message: '请输入锁粉店员信息',
								type: 'error'
							});
							return
						}
						data = {
							Phone: this.EditlevelForm.Lockcustomer
						}
					}
					//单个修改锁粉店员
					if (index == 3) {
						console.log(this.EditlockCustomer, '输入的手机号9999999')
						if (!this.EditlockCustomer) {
							this.$message({
								showClose: true,
								message: '请输入锁粉店员信息',
								type: 'error'
							});
							return
						}
						data = {
							Phone: this.EditlockCustomer
						}
					}
					//授权推手里面的
					if (index == 4) {
						console.log(this.DistrimemForm, '授权推手的查询')
						if (!this.DistrimemForm.VipLockCustomer) {
							this.$message({
								showClose: true,
								message: '请输入锁粉店员信息',
								type: 'error'
							});
							return
						}
						data = {
							Phone: this.DistrimemForm.VipLockCustomer
						}
					}

					let result = await SearchLockEmployee(data)
					if (result.IsSuccess) {
						this.EmployneeInfo = result.Result
						if (JSON.stringify(this.EmployneeInfo) == "{}") {
							this.IsSearch = true
						} else {
							this.IsSearch = false
						}
						if (index == 2) {
							this.UserInfoForm.LockMallEmployeeId = this.EmployneeInfo.Id
							this.oldPhone = this.UserInfoForm.VipLockCustomer
						}
						if (index == 1) {
							this.EditlevelForm.LockMallEmployeeId = this.EmployneeInfo.Id
							this.oldPhone = this.EditlevelForm.Lockcustomer
						}
						if (index == 3) {
							this.LockMallEmployeeId = this.EmployneeInfo.Id
							this.oldPhone = this.EditlockCustomer
						}
						if (index == 4) {
							this.DistrimemForm.LockMallEmployeeId = this.EmployneeInfo.Id
							this.oldPhone = this.DistrimemForm.VipLockCustomer
						}

					}

				} catch (err) {

				} finally {

				}

			},
			//获取会员等级
			async getUserlevels() {
				try {
					let result = await MemberUserlevels()
					this.VipLevelist = result.Result
				} catch (err) {

				} finally {

				}
			},
			//改变地址
			handleAddressChange() {
				this.UserInfoForm.ProvinceId = this.AddressArr[0]
				this.UserInfoForm.CityId = this.AddressArr[1]
				this.wePlacelist.map(item => {
					if (item.id == this.UserInfoForm.ProvinceId) {
						this.UserInfoForm.Province = item.name
						item.child.map(items => {
							if (items.id == this.UserInfoForm.CityId) {
								this.UserInfoForm.City = items.name
							}
						})
					}
				})
			},

			// 排序
			sortChange(column, prop, order) {

				this.OrderBy = column.prop == 'AverageOrderPriceShow' ? 'AverageOrderPrice' : column.prop;
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
				} else {
					this.IsAsc = false
				}
				this.currentPage = 1;
				console.log(this.OrderBy, '---this.OrderBy')
				console.log(this.IsAsc, '----this.IsAsc')
				this.getMemberlist();
			},
			//获取列表数据
			async getMemberlist() {
				this.loading = true
				try {
					let data = {
						MemberTypeId: this.ruleForm.MemberTypeId,
						WxAuthState: this.ruleForm.authorize,
						LastPayStartTime: (this.ruleForm.lastTime == null || this.ruleForm.lastTime.length == 0) ? '' : this.ruleForm.lastTime[
							0],
						LastPayEndTime: (this.ruleForm.lastTime == null || this.ruleForm.lastTime.length == 0) ? '' : this.ruleForm.lastTime[
							1],
						AddStartTime: (this.ruleForm.CustomTime == null || this.ruleForm.CustomTime.length == 0) ? '' : this.ruleForm.CustomTime[
							0],
						AddEndTime: (this.ruleForm.CustomTime == null || this.ruleForm.CustomTime.length == 0) ? '' : this.ruleForm.CustomTime[
							1],
						VipAuthStartTime: (this.ruleForm.UsersTime == null || this.ruleForm.UsersTime.length == 0) ? '' : this.ruleForm
							.UsersTime[0],
						VipAuthEndTime: (this.ruleForm.UsersTime == null || this.ruleForm.UsersTime.length == 0) ? '' : this.ruleForm.UsersTime[
							1],
						DistributorAuthStartTime: (this.ruleForm.DistriTime == null || this.ruleForm.DistriTime.length == 0) ? '' : this
							.ruleForm.DistriTime[0],
						DistributorAuthEndTime: (this.ruleForm.DistriTime == null || this.ruleForm.DistriTime.length == 0) ? '' : this.ruleForm
							.DistriTime[1],
						MemberIdentityIdList: this.ruleForm.identityIds,
						MemberTagIdList: this.ruleForm.labelIds,
						SourceTypeList: this.ruleForm.StyleIds,
						Gender: this.ruleForm.SexId,
						ProvinceIdList: this.ruleForm.wePlaceIds,
						VipBelongShopId: this.ruleForm.storeId,
						LockMallEmployeeId: this.ruleForm.lockId,
						KeyWord: this.ruleForm.searchKey,
						PointMin: this.ruleForm.minScore,
						PointMax: this.ruleForm.maxScore,
						PayCountMin: this.ruleForm.minPay,
						PayCountMax: this.ruleForm.maxpay,
						PayMoneyMin: this.ruleForm.mintotalPrice,
						PayMoneyMax: this.ruleForm.maxtotalPrice,
						AverageOrderPriceMin: this.ruleForm.minPrice,
						AverageOrderPriceMax: this.ruleForm.maxPrice,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc,
					}
					let result = await MemberList(data)

					this.groupData = result.Result.Results || [];

					console.log(this.groupData, '客户查询列表数据')
					this.Total = result.Result.Total;
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			//获取地址数据
			getDataList() {
				var that = this
				axios.get('http://cdn.dkycn.cn/json/citylist-include.json', {})
					.then(function(response) {
						that.wePlacelist = response.data
						that.wePlacelist.map(item => {
							if (item.child.length) {
								item.child.map(items => {
									if (items.child.length) {
										// items.child = []
										delete items.child
									}
								})
							}

						})
					})
					.catch(function(error) {})

			},
			//初始化数据
			async getMemberInit() {
				this.loading = true
				try {
					let result = await MemberInit()
					this.CusTypesList = result.Result.MemberTypeCountList
					this.CusTypesList.map(item => {
						if (item.Id == 4) {
							item.Prompt = '近30天新增并且没有支付成功过的客户'
						}
						if (item.Id == 5) {
							item.Prompt = '近7天有加购行为，但没有成功付款的客户'
						}
						if (item.Id == 6) {
							item.Prompt = '48小时内有在公众号发生过互动行为的粉丝，包括点击自定义菜单、发送消息、关注公众号、扫描带参数二维码'
						}
					})
					//隐藏了微信互动人数
					this.CusTypesList.splice(5, 1)
					//客户身份
					this.identityList = result.Result.MemberIdentityList
					this.labelList = result.Result.MemberTagList
					this.storeList = result.Result.ShopList
					this.lockList = result.Result.MallEmployeeList
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			ZeroSure(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						return false;
					}
				});
			},
			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			//查询
			handlefilter() {
				console.log(this.ruleForm.lastTime, '上次消费时间')
				this.currentPage = 1
				this.getMemberlist()
			},
			//清空积分
			ClearScore() {
				this.Scorezerovisi = true
			},
			//扣减积分
			ReduceSure(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.Reducscoreloading = true
						try {
							//单选

							if (this.ReductscoreIndex == 0) {
								if (this.ReduceForm.Num > 100000000) {
									this.$message({
										showClose: true,
										message: '扣减积分不能超过100000000',
										type: 'error'
									});
									this.Reducscoreloading = false
									return
								}
								let data = {
									Id: this.MemberShopid,
									ChangePoint: '-' + this.ReduceForm.Num,
									Reason: this.ReduceForm.Cause
								}
								let result = await Changesingelpoint(data)
								if (result.IsSuccess) {
									this.$message({
										showClose: true,
										message: '操作成功',
										type: 'success'
									});
									this.Reducevisi = false
									this.getMemberlist()
								}
							} else {
								if (this.ReduceForm.Num > 100000000) {
									this.$message({
										showClose: true,
										message: '扣减积分不能超过100000000',
										type: 'error'
									});
									this.Reducscoreloading = false
									return
								}
								let ids = this.multipleSelection.map(item => {
									return item.Id
								})
								let data = {
									MemberIdList: ids,
									ChangePoint: '-' + this.ReduceForm.Num,
									Reason: this.ReduceForm.Cause
								}
								let result = await Changesomepoints(data)
								if (result.IsSuccess) {
									this.$message({
										showClose: true,
										message: '操作成功',
										type: 'success'
									});
									this.Reducevisi = false
									this.getMemberlist()
								}
							}
						} catch (err) {

						} finally {
							this.Reducscoreloading = false
						}
					} else {
						return false;
					}
				});
			},
			// 赠送积分
			submitaddSure(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.Addscoreloading = true
						try {
							//单选
							if (this.AddscoreIndex == 0) {
								if (this.ScoreForm.Num > 100000000) {
									this.$message({
										showClose: true,
										message: '增加积分不能超过100000000',
										type: 'error'
									});
									this.Addscoreloading = false
									return
								}
								let data = {
									Id: this.MemberShopid,
									ChangePoint: this.ScoreForm.Num,
									Reason: this.ScoreForm.Cause
								}
								let result = await Changesingelpoint(data)
								if (result.IsSuccess) {
									this.$message({
										showClose: true,
										message: '操作成功',
										type: 'success'
									});
									this.Scorevisi = false
									this.getMemberlist()
								}
							}
							//多选
							else {
								if (this.ScoreForm.Num > 100000000) {
									this.$message({
										showClose: true,
										message: '增加积分不能超过100000000',
										type: 'error'
									});
									this.Addscoreloading = false
									return
								}
								let ids = this.multipleSelection.map(item => {
									return item.Id
								})
								let data = {
									MemberIdList: ids,
									ChangePoint: this.ScoreForm.Num,
									Reason: this.ScoreForm.Cause,
								}
								let result = await Changesomepoints(data)
								if (result.IsSuccess) {
									this.$message({
										showClose: true,
										message: '操作成功',
										type: 'success'
									});
									this.Scorevisi = false
									this.getMemberlist()
								}

							}
						} catch (err) {

						} finally {
							this.Addscoreloading = false
						}
					} else {
						return false;
					}
				})
			},
			closeScoreForm() {
				this.Scorevisi = false
				this.$nextTick(function() {
					this.$refs.ScoreForm.resetFields();
					// this.$refs.ScoreForm.clearValidate()
				})
				// this.$refs.ScoreForm.clearValidate()
			},
			//赠送积分
			AddScore(index, record) {
				console.log(index, '点击事件没有执行吗00000')
				this.AddscoreIndex = index
				this.ScoreForm.Num = ''
				this.ScoreForm.Cause = ''
				// 0单选,1多选
				if (index == 1) {
					if (this.multipleSelection.length == 0) {
						this.$message({
							showClose: true,
							message: '请选择赠送积分的客户',
							type: 'error'
						});
						return false
					} else {
						this.Scorevisi = true
					}
				} else {

					this.MemberShopid = record.Id
					this.Scorevisi = true
					console.log(this.Scorevisi, '积分的显示和隐藏9999是否执行')
				}


			},
			closeReduceForm() {
				this.Reducevisi = false
				this.$nextTick(function() {
					this.$refs.ReduceForm.resetFields();
					// this.$refs.ScoreForm.clearValidate()
				})
			},
			ReduceScore(index, record) {
				this.ReductscoreIndex = index
				this.ReduceForm.Num = ''
				this.ReduceForm.Cause = ''
				if (index == 1) {
					if (this.multipleSelection.length == 0) {
						this.$message({
							showClose: true,
							message: '请选择扣减积分的客户',
							type: 'error'
						});
						return false
					} else {
						this.Reducevisi = true
					}
				} else {
					this.MemberShopid = record.Id
					this.Reducevisi = true
				}
				// 				this.$nextTick(function() {
				// 					this.$refs.ReduceForm.clearValidate()
				// 				})
			},

			//设置标签
			setLabel(index, record) {
				this.memberLabelIndex = index
				// 0是在单选,1是多选
				if (index == 1) {
					//如果没有选，toast提示
					if (this.multipleSelection.length == 0) {
						this.$message({
							showClose: true,
							message: '请选择设置标签的客户',
							type: 'error'
						});
						return false

					}
					this.LabelNames = this.LabelNames.map(item => {
						item.checked = false
						return item
					})
					this.Labelvisi = true

				} else {
					this.Labelvisi = true
					this.MemberShopid = record.Id
					this.getMemberIDtags()
				}
			},
			//根据Id获取选中的标签
			async getMemberIDtags() {
				try {
					this.Setlabeloading = true
					let data = {
						Id: this.MemberShopid
					}
					let result = await MemberIDtags(data)
					if (result.IsSuccess) {
						if (result.Result.length) {
							this.LabelNames = this.LabelNames.map(item => {
								result.Result.map(items => {
									if (item.MemberTagName == items.Text) {
										item.checked = true
									}
								})
								return item
							})
						} else {
							this.LabelNames = this.LabelNames.map(item => {
								item.checked = false
								return item
							})

						}


					}
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.Setlabeloading = false
				}
			},
			async SavememberLable() {
				try {
					let noChecklabel = []
					this.LabelNames.map(item => {
						if (!item.checked) {
							noChecklabel.push(item)
						}
					})
					if (noChecklabel.length == this.LabelNames.length) {
						this.$message({
							showClose: true,
							message: '请选择标签',
							type: 'error'
						});
						return
					}
					//单选
					if (this.memberLabelIndex == 0) {
						let MemberTagIdList = []
						this.LabelNames.map(item => {
							if (item.checked) {
								MemberTagIdList.push(item.Id)
							}
						})
						let data = {
							Id: this.MemberShopid,
							MemberTagIdList: MemberTagIdList
						}
						let result = await SingelChangetags(data)
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								message: '标签设置成功',
								type: 'success'
							});
							this.Labelvisi = false
							this.getMemberlist()
						}
					} else {
						let MemberIdList = this.multipleSelection.map(item => {
							return item.Id
						})
						let MemberTagIdList = []
						this.LabelNames.map(item => {
							if (item.checked) {
								MemberTagIdList.push(item.Id)
							}
						})
						let data = {
							MemberIdList: MemberIdList,
							MemberTagIdList: MemberTagIdList
						}
						let result = await EditsomeTags(data)
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								message: '标签设置成功',
								type: 'success'
							});
							this.Labelvisi = false
							this.getMemberlist()
						}
						// EditsomeTags
					}
				} catch (err) {

				} finally {

				}
			},

			SingleIndex(item, index) {
				this.currentIndex = index
				this.ruleForm.MemberTypeId = item.Id
				this.currentPage = 1
				this.getMemberlist()
			},
			handleSelectionChange(val) {

				this.multipleSelection = val;
				this.isCurrentAll = val.length == this.groupData.length ? true : false
				if (this.isCurrentAll) {
					this.checkedAll = true
				} else {
					this.checkedAll = false
				}
				
				console.log(this.checkedAll,'当前页全选是否被选中')
			},
			Checkdetail(record) {
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/Customer/CustomerDetail?Id=' + record.Id
				window.open(url)
				// this.$router.push({
				// 	path: "/Customer/CustomerDetail",
				// 	query: {
				// 		Id: record.Id
				// 	}
				// })
			},
			tabCheckedChange(val) {
				this.$refs['multipleTable'].toggleAllSelection();
				// console.log(this.multipleSelection, '没有值吗吗')
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getMemberlist();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getMemberlist();
			},
		},
	}
</script>

<style lang="less" scoped>
	.Userbox {
		font-size: 14px;

		.title {
			color: #101010;
		}
	}

	.selectBox {
		font-size: 14px;

		// flex-wrap: wrap;

		.selectItem {
			display: inline-block;
			width: 280px;
			margin-bottom: 15px;
			font-size: 14px;

			.checkI {
				margin-right: 15px;
			}
		}
	}

	.productInfo {
		display: flex;

		.Activestyle {
			font-size: 12px;
			background: #F56C6C;
			color: #fff;
			text-align: center;
			padding-left: 4px;
			padding-right: 4px;
			box-sizing: border-box;

		}
	}

	.productInfo .Imgs img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		border: none;
		object-fit: initial;
	}

	.productInfo .overFlow {
		width: 100px;
		margin-right: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.filter-container .filter-item {
		margin-right: 0px !important;
		margin-bottom: 0px !important;
	}

	.borderColor {
		border: 1px solid #ddd;
	}

	.ActiveClass {
		border: 1px solid #409EFF;
		background: #D9ECFF;
	}

	.displayCommon {
		display: -webkit-box;
		/* Chrome 4+, Safari 3.1, iOS Safari 3.2+ */
		display: -moz-box;
		/* Firefox 17- */
		display: -webkit-flex;
		/* Chrome 21+, Safari 6.1+, iOS Safari 7+, Opera 15/16 */
		display: -moz-flex;
		/* Firefox 18+ */
		display: -ms-flexbox;
		/* IE 10 */
		display: flex;
		/* Chrome 29+, Firefox 22+, IE 11+, Opera 12.1/17/18, Android 4.4+ */
	}

	.CusCommon {
		background: #fff;
		padding: 10px 20px 20px 20px;
		box-sizing: border-box;
		// margin-bottom: 10px;
	}

	.CusInfo {
		font-size: 14px;

		.CusTypes {
			margin-top: 10px;
			flex-wrap: wrap;
			width: 80%;
			justify-content: space-between;

			.Item {
				width: 14%;
				padding: 10px 10px 10px 20px;
				box-sizing: border-box;
				cursor: pointer;

				&:last-child {
					margin-right: 0;
				}

				.NumStyle {
					font-size: 12px;
					color: #666;
					margin-top: 8px;
				}
			}
		}
	}
</style>
